export const signUpForm = async (email, name, message) => {
  const jsonFormData = {
    name: name,
    email: email,
    phone: "Odex",
    message: message,
  };
  return await fetch(
    "http://mosaic-dev.eba-dmmzqk22.us-west-2.elasticbeanstalk.com/api/v1/sign-up-form",
    // "http://127.0.0.1:50000/api/v1/sign-up-form",
    {
      method: "POST",
      body: JSON.stringify(jsonFormData),
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((response) => console.log(response.json()));
};
