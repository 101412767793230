import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { CometChat } from "@cometchat-pro/chat";
import {
  CometChatMessages,
  CometChatUI,
} from "../../src/cometchat-pro-react-ui-kit/CometChatWorkspace/src";

const appID = "213786170e9df7c7";
const region = "us";
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    const authKey = "e7827ecf25b662da5107b3820cf3ff6510108f93";
    const uid = "1";

    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("Login Successful:", { user });
      },
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

export default () => {
  return (
    <div style={{ width: "100%", height: "900px" }}>
      <CometChatUI chatWithUser="superhero2" />
    </div>
  );
};
