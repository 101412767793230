import { CometChat } from "@cometchat-pro/chat";
import { useEffect } from "react";

const startCall = () => {
  let sessionID = "SESSION_ID";
  let audioOnly = false;
  let defaultLayout = true;

  let callSettings = new CometChat.CallSettingsBuilder()
    .enableDefaultLayout(defaultLayout)
    .setSessionID(sessionID)
    .setIsAudioOnlyCall(audioOnly)
    .build();

  CometChat.startCall(
    callSettings,
    document.getElementById("callScreen"),
    new CometChat.OngoingCallListener({
      onUserListUpdated: (userList) => {
        console.log("user list:", userList);
      },
      onCallEnded: (call) => {
        console.log("Call ended:", call);
      },
      onError: (error) => {
        console.log("Error :", error);
      },
      onMediaDeviceListUpdated: (deviceList) => {
        console.log("Device List:", deviceList);
      },
      onUserMuted: (userMuted, userMutedBy) => {
        // This event will work in JS SDK v3.0.2-beta1 & later.
        console.log("Listener => onUserMuted:", userMuted, userMutedBy);
      },
      onScreenShareStarted: () => {
        // This event will work in JS SDK v3.0.3 & later.
        console.log("Screen sharing started.");
      },
      onScreenShareStopped: () => {
        // This event will work in JS SDK v3.0.3 & later.
        console.log("Screen sharing stopped.");
      },
      onCallSwitchedToVideo: (
        sessionId,
        callSwitchInitiatedBy,
        callSwitchAcceptedBy
      ) => {
        // This event will work in JS SDK v3.0.8 & later.
        console.log("call switched to video:", {
          sessionId,
          callSwitchInitiatedBy,
          callSwitchAcceptedBy,
        });
      },
    })
  );
};

export default () => {
  useEffect(() => startCall());
  return <div id="callScreen" style={{ width: "100%", height: "900px" }}></div>;
};
