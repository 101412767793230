import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import googleIconImageSrc from "images/google-icon.png";
import jordanProfile from "images/jordan_profile.jpg";
import shaneProfile from "images/shane_profile.png";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Odex</Subheading>}
        heading="We are a technology learning company"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="Odex is the first online marketplace that connects topic experts with everyday learners in a 1:1 setting"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to take learning to the next level"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description="Our goal is to help our customers save time and to give the world access to 1:1 training when they need it"
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="What we stand for"
        description="To us, our value are everything. It's how we ensure we are doing right by our customers"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Customer First",
            description:
              "We care about you and want to make sure you have the absolute best experience when using our product or working with us",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "We are focused on building a first class team to help our customer problems.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Make A Difference",
            description:
              "We want to truly make a different for people around the world. We won't stop until everyone has access to 1:1 ",
          },
        ]}
        linkText=""
      />
      <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
        cards={[
          {
            imageSrc: jordanProfile,
            position: "Co-Founder",
            name: "Jordan Thiel",
            links: [
              {
                url: "https://linkedin.com/in/jordanthiel",
                icon: LinkedinIcon,
              },
            ],
          },
          {
            imageSrc: shaneProfile,
            position: "Co-Founder",
            name: "Shane Springer",
            links: [
              {
                url: "https://www.linkedin.com/in/shanespringer",
                icon: LinkedinIcon,
              },
            ],
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
