import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import happyIllustrationImagesSrc from "images/happy-illustration.svg";
import professionalIllustrationImageSrc from "images/professional-illustration.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

//Image imports for cards
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <MainFeature
        subheading={<Subheading>Save Time</Subheading>}
        imageSrc={professionalIllustrationImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        heading={
          <>
            Save 60% more <span tw="text-primary-500">Time.</span>
          </>
        }
        description="Odex is solving the problem of time. Instead of endlessly searching Google for help to a problem you have, you can now connect directly with an expert to guide and teach you."
      />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Putting our customers <HighlightedText>first.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: FastIconImage,
            title: "Fast",
            description:
              "Get help solving your problem and save time from the endless Google searching",
          },
          {
            imageSrc: SimpleIconImage,
            title: "Personalized",
            description: "Get personalized help from experts in any subject",
          },
          {
            imageSrc: ReliableIconImage,
            title: "Reliable",
            description:
              "Odex is built on top of premier services like Zoom and AWS to ensure it always works",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Secure",
            description:
              "We use technology that is known for having top-notch security like Zoom and AWS",
          },
          {
            imageSrc: SupportIconImage,
            title: "Categories",
            description: "Get access to a wide variety of subjects and topics",
          },
          {
            imageSrc: CustomizeIconImage,
            title: "Save Time",
            description: "Save time and money by learning from experts",
          },
        ]}
      />

      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={happyIllustrationImagesSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={[
          {
            heading: "Register",
            description: "Create an account with us using Google or Facebook.",
          },
          {
            heading: "Search",
            description:
              "Search for the category that best fits the problem you're trying to solve.",
          },
          {
            heading: "Connect",
            description: "Select and connect with an expert to help you out.",
          },
        ]}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      /> */}
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Users <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description="See what our early users are saying about us!"
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Odex unblocked me so fast!",
            quote:
              "I used to just spend endless time on Google and Stack Overflow whenever I was stuck on an issue while coding. It was so frustrating and would sometimes take me multiple days to finally find what I was looking for. Now with Odex, I've been able to get past these roadblocks so much faster and actaully understand what I am implementing. Thanks to my amazing expert for helping me out and being so great!",
            customerName: "Charlotte Hale",
            customerTitle: "Software Engineer",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "It was so simple !",
            quote:
              "Everything about Odex has been great. I have been able to learn so much more advanced topics and I am actually comprehending the material because I work much better in a 1:1 setting. The experts are great and have really helped me learn new skills as I am building my new business.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder",
          },
        ]}
      />
      <FAQ
        id={"faq"}
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        description="And we have answers to all of them. Don't see your question? Reach out to us and we'll get back to you soon."
        faqs={[
          {
            question: "How much does it cost ?",
            answer:
              "The expert instructors set their rate based on the topic, experience and market standards so it will vary based on who you would like to work with.",
          },
          {
            question:
              "Is there a subscription fee or do I only need to pay when I actually connect with an expert instructor?",
            answer:
              "We are currently not chargin any subscription fee so the only time you pay will be when you have a 1:1 session with an expert instructor.",
          },
          {
            question: "What do you use for the video calling service?",
            answer:
              "We have built our own secure video calling service with the help of the Zoom Video SDK that grants us access to their core technology. This ensures you get the very best and the technology doesn't get in the way of your learning.",
          },
          {
            question: "Are any of my 1:1 session recorded?",
            answer:
              "No! We do not record anything without your consent. However, if you want to be able to watch it back, there is an option to record it yourself so you can play it back later.",
          },
          {
            question: "Can I sign up for weekly instruction?",
            answer:
              "We let the instructor choose how they want to set up instruction but most instructors we see do have the option to set up weekly instruction and some even offer a discount for it.",
          },
          {
            question: "What browswers are supported?",
            answer:
              "We support all major browswers including Chrome, IE11, Firefox, Safari",
          },
        ]}
      />
      <GetStarted
        text="People all over the world are using Odex to learn and save time "
        primaryLinkUrl="/signup"
        secondaryLinkUrl="/contact"
      />
      <Footer />
    </AnimationRevealPage>
  );
};
